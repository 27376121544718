import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import { useRect } from "@reach/rect"
import "sanitize.css"

import Header from "../components/Header"
import FullScreenVideo from "../components/FullScreenVideo"
import Overlay from "../components/Overlay"
import liveFootage from "../assets/videos/live2.mp4"

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #060606;
        color: #fff;
        font-family: sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        overflow-x: hidden;
    }
`

const StyledMain = styled("main")`
    height: 100%;
`

const Background = styled("div")`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const Content = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    @media (max-width: 1200px) {
        width: calc(100% - 2rem);
        // height: 100%;
        min-height: ${props =>
            props.offset
                ? `calc(100vh - ${String(props.offset) + "px"})`
                : "unset"};
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: ${props => String(props.offset) + "px" || "unset"};
    }
`

const PageWrapper = ({ children, location }) => {
    const headerElement = useRef(null)
    const headerRect = useRect(headerElement)
    const [isMenuHidden, setIsMenuHidden] = useState(false)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        const isRefValid = headerRect && headerRect.width
        if (!isRefValid) {
            return
        }
        if (headerRect.width !== width) {
            setWidth(headerRect.width)
        }
    }, [headerRect, width, setWidth])
    useEffect(() => {
        if (width < 768) {
            setIsMenuHidden(true)
        } else {
            setIsMenuHidden(false)
        }
    }, [width, setIsMenuHidden])
    useEffect(() => {
        if (width < 768) {
            setIsMenuHidden(true)
        }
    }, [width, location.key, setIsMenuHidden])
    useLayoutEffect(() => {
        const isRefValid = headerRect && headerRect.height
        if (!isRefValid) {
            return
        }
        if (headerRect.height !== height) {
            setHeight(headerRect.height)
        }
    }, [headerRect, height, setHeight])
    useLayoutEffect(() => {
        if (isMenuHidden) {
            setOffset(height)
        }
    }, [isMenuHidden, height])
    const data = useStaticQuery(graphql`
        query GetBackgroundImage {
            backgroundImage: airtable(
                table: { eq: "Images" }
                data: { ID: { eq: 2 } }
            ) {
                data {
                    Image {
                        localFiles {
                            childImageSharp {
                                fluid(maxWidth: 5000, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    return (
        <React.Fragment>
            <GlobalStyle />
            <Header
                ref={headerElement}
                isMenuHidden={isMenuHidden}
                toggleIsMenuHidden={() => setIsMenuHidden(state => !state)}
            />
            <StyledMain>
                <Background>
                    <FullScreenVideo
                        videoSrc={liveFootage}
                        fallbackImageSrc={
                            data.backgroundImage.data.Image.localFiles[0]
                                .childImageSharp
                        }
                        screenWidth={width}
                        autoPlay
                        muted
                        loop
                    />
                    <Overlay />
                </Background>
                <Content offset={offset}>{children}</Content>
            </StyledMain>
        </React.Fragment>
    )
}

export default PageWrapper
