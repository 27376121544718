// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("/opt/build/repo/src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-press-js": () => import("/opt/build/repo/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-releases-js": () => import("/opt/build/repo/src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */)
}

