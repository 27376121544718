import React, { useState, useEffect } from "react"
import Image from "gatsby-image"
import styled from "styled-components"

const StyledVideo = styled("video")`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
`

// fallback image
const StyledImage = styled(Image)`
    position: relative;
    height: 100%;
    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.66);
    }
`

// a responsive video component
const FullScreenVideo = ({
    videoSrc,
    fallbackImageSrc,
    screenWidth,
    ...props
}) => {
    const [showImage, setShowImage] = useState(false)
    useEffect(() => {
        if (screenWidth >= 768) {
            if (showImage) {
                setShowImage(false)
            }
        } else {
            if (!showImage) {
                setShowImage(true)
            }
        }
    }, [screenWidth, showImage])
    const isGatsbyImage = typeof fallbackImageSrc === "object"
    const imageSrc = {
        src: isGatsbyImage ? null : fallbackImageSrc,
        fluid: isGatsbyImage ? fallbackImageSrc.fluid : null
    }
    return (
        <React.Fragment>
            <StyledVideo src={showImage ? "" : videoSrc} {...props} />
            {showImage && <StyledImage {...imageSrc} />}
        </React.Fragment>
    )
}

export default FullScreenVideo
