import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ExternalAnchor = ({ noDecoration, children, ...props }) => (
    <a {...props}>{children}</a>
)
const InternalAnchor = ({ noDecoration, children, ...props }) => (
    <Link {...props}>{children}</Link>
)

const StyledLink = styled("a")`
    color: #fff;
    text-decoration: ${props => (props.noDecoration ? "none" : "underline")};
`

const ExternalLink = props => <StyledLink as={ExternalAnchor} {...props} />
const InternalLink = props => <StyledLink as={InternalAnchor} {...props} />

const LinkAdapter = props => {
    if (props.to) {
        return <InternalLink {...props} />
    } else if (props.href) {
        return <ExternalLink {...props} />
    } else {
        console.log("why")
        return null
    }
}

export default LinkAdapter
