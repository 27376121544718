import React from "react"
import styled from "styled-components"

import Link from "./Link"
import NakedList from "./NakedList"
import menuIcon from "../assets/vectors/menu.svg"
import closeIcon from "../assets/vectors/x.svg"
import bandcampIcon from "../assets/vectors/bandcamp.svg"
import spotifyIcon from "../assets/vectors/spotify.svg"
import appleMusicIcon from "../assets/vectors/apple-music.svg"
import youtubeIcon from "../assets/vectors/youtube.svg"
import facebookIcon from "../assets/vectors/facebook.svg"
import twitterIcon from "../assets/vectors/twitter.svg"
import instagramIcon from "../assets/vectors/instagram.svg"

const listenLinks = [
    {
        label: "Listen on Bandcamp",
        icon: bandcampIcon,
        url: "https://shinguard.bandcamp.com/"
    },
    {
        label: "Listen on Spotify",
        icon: spotifyIcon,
        url:
            "https://open.spotify.com/artist/08GZvVeyt2iu5Am0t05cC4?si=uVl7_vwUQS22C0TCuySP4A"
    },
    {
        label: "Listen on Apple Music",
        icon: appleMusicIcon,
        url: "https://music.apple.com/us/artist/shin-guard/1323085302"
    },
    {
        label: "Listen on YouTube",
        icon: youtubeIcon,
        url: "https://www.youtube.com/channel/UCm86TpVbV1XYBdTNitPMy5A"
    }
]

const socialMediaLinks = [
    {
        label: "Follow on Facebook",
        icon: facebookIcon,
        url: "https://www.facebook.com/shinguardpgh/"
    },
    {
        label: "Follow on Twitter",
        icon: twitterIcon,
        url: "https://twitter.com/shinguardpgh"
    },
    {
        label: "Follow on Instagram",
        icon: instagramIcon,
        url: "https://www.instagram.com/shinguardpgh/"
    }
]

const navLinks = [
    {
        label: "Shows",
        to: "/#shows"
    },
    {
        label: "Merch",
        href: "https://shop.shinguardhc.com"
    },
    {
        label: "Releases",
        to: "/releases"
    },
    {
        label: "Media",
        to: "/media"
    },
    {
        label: "Press",
        to: "/press"
    },
    {
        label: "Contact",
        href: "mailto:shinguardpgh@gmail.com"
    }
]

const StyledDiv = styled("div")`
    width: 100%;
    display: ${props => (props.isHidden ? "none" : "flex")};
    justify-content: space-between;
    @media (max-width: 767px) {
        justify-content: flex-end;
        height: 100vh;
    }
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`

const StyledNav = styled("nav")``

const LinkList = styled(NakedList)`
    margin: 0;
    padding: 2rem;
    display: flex;
    justify-content: center;
    & > * {
        margin: 0.5rem 1rem;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
`

const Row = styled("div")`
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
    }
    @media (min-width: 1200px) {
        padding-left: 2rem;
    }
`

const RowList = styled(NakedList)`
    margin: 0;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    & > * {
        margin: 0 1rem;
    }
    @media (max-width: 767px) {
        padding: 1rem;
    }
`

const StyledLink = styled(Link).attrs(props => ({
    ...props,
    noDecoration: true
}))`
    font-weight: 700;
    text-transform: uppercase;
`

const ExternalLinks = () => (
    <Row>
        <RowList>
            {listenLinks.map((link, index) => (
                <Link
                    key={index}
                    href={link.url}
                    title={link.label}
                    noDecoration
                >
                    <img src={link.icon} alt={link.label} />
                </Link>
            ))}
        </RowList>
        <RowList>
            {socialMediaLinks.map((link, index) => (
                <Link
                    key={index}
                    href={link.url}
                    title={link.label}
                    noDecoration
                >
                    <img src={link.icon} alt={link.label} />
                </Link>
            ))}
        </RowList>
    </Row>
)

const NavLinks = () => (
    <StyledNav>
        <LinkList>
            {navLinks.map((link, index) => (
                <StyledLink key={index} {...link}>
                    {link.label}
                </StyledLink>
            ))}
        </LinkList>
    </StyledNav>
)

const Links = ({ isHidden, ...props }) => (
    <StyledDiv isHidden={isHidden} {...props}>
        <ExternalLinks />
        <NavLinks />
    </StyledDiv>
)

const StyledHeader = styled("header")`
    position: fixed;
    z-index: 999;
    bottom: 0;
    width: 100%;
    @media (max-width: 1200px) {
        background-color: rgba(0, 0, 0, 0.88);
    }
`

const MenuButton = styled("button")`
    display: flex;
    justify-content: center;
    width: 100%;
    border: 0;
    padding: 1.25rem;
    background-color: transparent;
    color: #fff;
    @media (min-width: 768px) {
        display: none;
    }
`

const Menu = ({ isHidden, toggleIsHidden }) => {
    return (
        <React.Fragment>
            <Links isHidden={isHidden} />
            <MenuButton onClick={toggleIsHidden}>
                <img src={isHidden ? menuIcon : closeIcon} alt="menu" />
            </MenuButton>
        </React.Fragment>
    )
}

const Header = React.forwardRef((props, ref) => (
    <StyledHeader ref={ref} {...props}>
        <Menu
            isHidden={props.isMenuHidden}
            toggleIsHidden={props.toggleIsMenuHidden}
        />
    </StyledHeader>
))

export default Header
